<template>
	<div
		class="login"
		:style="{
			width: '100vw',
			background: 'url(' + require('@/assets/login_bg.png') + ') no-repeat center',
			backgroundSize: 'cover',
		}"
	>
		<div class="l-title">
			<div>欢迎使用</div>
			<div>智慧教室平台</div>
		</div>
		<div class="l-group">
			<van-field v-model="form.user" left-icon="contact" placeholder="请输入用户名" />
			<van-field
				v-model="form.pwd"
				left-icon="contact"
				type="password"
				placeholder="请输入密码"
			/>
			<!-- <van-field
        v-model="form.phone"
        left-icon="contact"
        type="tel"
        placeholder="请输入手机号码"
      />
      <van-field
        v-model="form.code"
        left-icon="contact"
        placeholder="请输入验证码"
      >
        <template #right-icon>
          <div class="get-code">
            <span>|</span>
            <span @click="getCode">
              <i v-show="!codeState">获取验证码</i>
              <van-count-down
                :disabled="codeState"
                ref="countDown"
                format="ss"
                :auto-start="false"
                @finish="codeState = false"
                v-show="codeState"
                :time="time"
              >
                <template #default="timeData">
                  重新发送{{ timeData.seconds }}
                </template>
              </van-count-down>
            </span>
          </div>
        </template>
      </van-field> -->
			<van-button
				:color="defineStyle.themeColor"
				@click="btnLogin"
				block
				round
				class="l-btn"
				type="primary"
				size="large"
				>登录</van-button
			>
		</div>
	</div>
</template>
<script>
import { Form, Field, CountDown, Button, Toast } from 'vant'
import { systemLogin } from '@/api/axios_login.js'
import defineMethods from '@/methods'
export default {
	components: {
		[Form.name]: Form,
		[Field.name]: Field,
		[CountDown.name]: CountDown,
		[Button.name]: Button,
	},
	data() {
		return {
			codeState: false,
			time: 60000,
			form: {
				user: '',
				pwd: '',
				phone: '',
				code: '',
				openId: '',
			},
		}
	},
	methods: {
		getCode() {
			this.codeState = true
			if (this.codeState) {
				this.$refs.countDown.start()
			}
		},
		//登陆
		async btnLogin() {
			if (defineMethods.isNullOrEmpty(this.form.user)) {
				Toast('请填写账号')
				return false
			}
			if (defineMethods.isNullOrEmpty(this.form.pwd)) {
				Toast('请填写密码')
				return false
			}
			let res = await systemLogin(this.form.user, this.form.pwd, this.form.openId)
			if (res.ResultCode != 200) {
				Toast(res.Message)
				return false
			}
			defineMethods.setCookie('LoginUserInfo', JSON.stringify(res.Data))
			this.$router.push('/SmartClassroom/Index')
		},
	},
	computed: {},
	mounted() {
		let user = defineMethods.getCookie('WxUserInfo')
		if (!defineMethods.isNullOrEmpty(user)) user = JSON.parse(user)
		this.form.openId = user.openid
		// this.$set(this.form, "openId", user.openid)
	},
	updated() {},
}
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.login {
	width: 100%;
	height: 100%;
	.l-title {
		margin-top: 92px;
		margin-left: 24px;
		color: $titleColor;
		font-size: 28px;
		font-weight: 500;
	}
	.l-group {
		padding-top: 60px;
		.l-btn {
			margin-top: 48px;
		}
		.van-button {
			border-radius: 999px;
			margin-left: 16px;
			margin-right: 16px;
			width: 343px;
		}
		.van-cell {
			width: auto;
			background-color: $bgColor;
			height: 44px;
			line-height: 44px;
			padding: 0;
			margin: 0 16px 16px 16px;
			padding-left: 24px;
			border-radius: 22px;
			color: $gray;
			font-size: 16px;
			font-weight: 400;
		}
		.van-icon {
			color: $gray;
			margin-right: 6px;
		}
		.get-code {
			font-size: 13px;
			font-weight: 400;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			transform: translateX(-20%);
			align-items: center;
			> span:first-child {
				margin-right: 6px;
				color: $gray;
			}
			> span:last-child {
				color: #fd9232;
				.van-count-down {
					color: $gray;
				}
			}
		}
	}
}
</style>
